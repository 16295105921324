import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import Meta from "../components/meta"
import Footer from "../components/footer"
import TitleHeader from "../components/titleHeader"
import BodyText from "../components/bodyText"
import SectionTitle from "../components/sectionTitle"
import ShadowImage from "../components/shadowImage"
import "../styles/pages/_about.scss"

export default class AboutPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll)
  }

  render() {
    const aboutData = this.props.data.allContentfulAboutPage.nodes[0]
    const generalData = this.props.data.allContentfulGeneral.nodes[0]

    return (
      <div className="about">
        <Meta
          title={aboutData.seo.title}
          description={aboutData.seo.description.description}
          image={aboutData.seo.socialImage.file.url}
          url={this.props.location.href}
          imgAlt={aboutData.seo.socialImage.description}
        />
        <Header />
        <TitleHeader title={aboutData.pageTitle} />
        <div className="about-overview">
          <BodyText text={aboutData.companyOverview.json} />
          <div className="overview-images overlap-content">
            <ShadowImage
              image={aboutData.overviewImages[0]}
              shadow={generalData.redShadow}
            />
            <ShadowImage
              image={aboutData.overviewImages[1]}
              shadow={generalData.redShadow}
            />
          </div>
        </div>
        <div className="about-overview-mobile overlap-content">
          <ShadowImage
            image={aboutData.overviewImages[1]}
            shadow={generalData.redShadow}
          />
          <BodyText text={aboutData.companyOverview.json.content[0]} />
          <ShadowImage
            image={aboutData.overviewImages[0]}
            shadow={generalData.redShadow}
          />
          <BodyText text={aboutData.companyOverview.json.content[1]} />
        </div>
        <div className="management">
          <SectionTitle title={aboutData.managementTitle} />
          <div className="management-content">
            <ul className="management-container">
              {aboutData.managementTeam.map((person, n) => {
                return (
                  <li key={n}>
                    <Img
                      fluid={person.image.fluid}
                      alt={person.image.description}
                    />
                    <div className="name"> {person.name} </div>
                    <div className="position"> {person.title} </div>
                  </li>
                )
              })}
            </ul>
          </div>
          <BackgroundImage
            fluid={aboutData.managementShadow.fluid}
            className="management-img"
          ></BackgroundImage>
        </div>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query AboutPageQuery {
    allContentfulAboutPage {
      nodes {
        pageTitle
        companyOverview {
          json
        }
        overviewImages {
          description
          file {
            url
          }
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        managementTeam {
          image {
            description
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          name
          title
        }
        managementTitle
        managementShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        directorsTitle
        directors {
          name
          bio {
            json
          }
        }
        seo {
          title
          description {
            description
          }
          socialImage {
            description
            file {
              url
            }
          }
        }
      }
    }
    allContentfulGeneral {
      nodes {
        darkShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        redShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
